import React from 'react';

const IconStar = () => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-star">
    <title>Star</title>
    <polygon points="39 0 0 22 0 67 39 90 78 68 78 23" />
  </svg>
);

export default IconStar;
